import React from 'react';
import Accordion from '../components/Accordion/Accordion';
import Layout from '../components/Layout';
import SEO from '../components/SEO';

const productAgreementFaq = [
  {
    question: '1. Introduction',
    answer: (
      <p>
        This agreement contains important information you should know about your
        deposit relationship with Aeldra Financial. It is important that you
        understand exactly how your Aeldra Checking Account works. Please review
        this agreement thoroughly. And if you have any questions, please contact
        us anytime at{' '}
        <a href="mailto:concierge@aeldra.com">concierge@aeldra.com</a>, or use
        the message center in the Aeldra App or call our customer service at{' '}
        <a href="tel:1-844-333-3101">1-844-333-3101</a>.
      </p>
    ),
  },
  {
    question: '2. Our Agreement',
    answer: (
      <>
        <p>
          This agreement contains the Aeldra Product/Checking Account Agreement
          (the “Agreement”, the “Deposit Account Agreement”) for the Aeldra
          Checking Account (“Checking Account”, “Premier Checking Account”,
          “Student CheckingAccount”, “Deposit Account”) made available to
          eligible consumers by Blue Ridge Bank. N.A., member of the Federal
          Deposit Insurance Corporation (“FDIC”) (the “Bank” or “Issuer”) on
          behalf of Aeldra Financial Inc. (“Aeldra”). Aeldra is2the program
          partner responsible for managing the Checking Account. “We”, “our”,
          and “us” refer to Aeldra, our successors, affiliates, or assignees.
          “You”, “your”, “Account holder” and “member” refer to the owner of the
          Checking Account. This Agreement is revised periodically, so it may
          include changes from earlier versions. By providing a written or
          electronic signature on opening, or continuing to hold an account with
          us, you agree to the most recentversion of this Agreement, which is
          available to you at <a href="www.aeldra.com">www.aeldra.com</a>, or by
          calling the Aeldra Concierge at{' '}
          <a href="tel:1-844-333-3101">1-844-333-3101</a>.
        </p>

        <p>
          The Checking Account is a demand deposit account available from Blue
          Ridge Bank N.A. and is an interest-bearingchecking account. Applicants
          may submit a request to open an Aeldra Checking Account by using the
          Aeldra mobilebanking application (the “Aeldra App”). The Aeldra
          Checking Account is not designed for business use, and we mayclose the
          account if we determine it is being used for business purposes. We may
          refuse to process anytransaction(s) we believe may violate the terms
          of this Agreement.
        </p>
      </>
    ),
  },
  {
    question: '3. Cellular Phone Contact Policy',
    answer: (
      <p>
        By providing us with a telephone number for a cellular phone or other
        wireless device, including a number that you later connect to a cellular
        device, you are expressly consenting to receiving
        communications-including but notlimited to prerecorded or artificial
        voice message calls, text messages, and calls made by an automatic
        telephone dialing system-from us, Aeldra, our affiliates and our agents,
        at that number. This express consent applies to each such telephone
        number that you provide to us now or in the future and permits such
        calls for non-marketing purposes. Calls and messages may incur access
        fees from your cellular provider.
      </p>
    ),
  },
  {
    question: '4. Eligibility',
    answer: (
      <p>
        To be eligible for an Aeldra checking account you must: (a) accept and
        agree to Aeldra’s Terms and our Privacy Policy (b) register with us on
        the Website or the Application; (c) be a Resident of the United States
        or a Citizen of India, (d) be at least 18 years old; (e) Indian
        residents will be restricted from debit card usage until the customer is
        physically in the U.S. Customers will have to follow applicable Aeldra
        procedures which will include providing documentation that establishes
        physical presence in the U.S. and by contacting Aeldra Concierge to
        remove the usage restriction; (f) provide all information requested by
        us, such as your name, email address, mobile device number, online
        credentials for your Bank Account, and such other information as we may
        request from time to time (collectively, “User Information”); and (g)
        only use these Services in compliance with any and all applicable laws
        and regulations. You must also agree to accept electronic, rather than
        paper statements. This means; (i) you must keepus supplied with your
        valid email address; and (ii) you must agree to accept electronic
        delivery of all account communications (like end-of-year tax forms and
        electronic statements). We may use information from third parties to
        help us determine if we should open the Aeldra Checking Account.
      </p>
    ),
  },
  {
    question:
      '5. Important information about procedures for opening a new account*',
    answer: (
      <p>
        To help the government fight the funding of terrorism and money
        laundering activities, federal law requires all financial institutions
        to obtain, verify, and record information that identifies each person
        who opens an Account.What this means for you: When you open an Account,
        we will ask for your name, address, date of birth, and other information
        that will allow us to identify you. We may also ask to see a copy of
        your passport, driver’s license, or other identifying documents.
      </p>
    ),
  },
  {
    question: '6. The Aeldra checking account*',
    answer: (
      <>
        <p>
          The Aeldra Checking Account consists of the online transaction demand
          deposit account used to make payments and transfers to third parties
          online or through the use of an Aeldra Mastercard® Debit Card
          (“Card”). The Aeldra Checking Account is a personal, checkless
          account. You may not issue paper checks with the Aeldra Checking
          Account.{' '}
        </p>
        ‍
        <p>
          6.1. Account Titling and Ownership
          <br /> The Aeldra Checking Account may only be owned and titled in the
          name of one person who may deposit, transfer,or withdraw funds. The
          Checking Account cannot be owned or titled jointly, by an
          organization, as Payable on Death(“POD”) or “In Trust For” (“ITF”).
        </p>
        <p>
          6.2. Death or Incapacitation
          <br />
          You or your appointed party, designee, or appointed individual agree
          to notify us promptly if you become legally incapacitated, are deemed
          incompetent, or die. We will continue to accept deposits and process
          transaction instructions into and from the Aeldra Checking Account
          until we are: (a) notified of your death or adjudication of
          incompetency and (b) have a reasonable opportunity to act. You agree
          that, even if we have knowledge of your death we may pay or process
          transactions involving the Aeldra Checking Account on or before the
          date of death for up to ten (10) days after that date unless ordered
          to stop by someone claiming interest in the Aeldra Checking Account.
          We may require additional documentation to confirm any claims made on
          the Aeldra Checking Account.
        </p>
        ‍
        <p>
          6.3. Power of Attorney
          <br />
          ‍You may give another person authority to make transactions on your
          Checking Account by giving power of attorney to another individual.
          The account owner and person executing power of attorney over a
          deposit account is known as the “Principal.” The person granted Power
          of Attorney for the Principal is known as the “Agent.” We may refuse
          toaccept a power of attorney for reasonable cause, and we may require
          the Agent to sign an affidavit stating that the power of attorney
          presented to us is a true copy and that, to the best of the Agent’s
          knowledge, the Principal continues to be alive and competent and that
          the relevant powers of the Agent have not been amended or terminated.
          The Principal is responsible to provide us with any information if an
          affidavit presented to us is untrue or misleading or if the Agent
          exceeds the authority granted by the Principal in the power of
          attorney. The Agent is required to notify us in writing if the
          Principal dies or is declared incompetent. The power of attorney will
          continuein force until a) we receive written revocation from the
          Principal; b) we receive written notification of the Principal’s
          death, or c) we receive written notification of the death or
          incapacity of the Agent.
        </p>
        ‍
        <p>
          6.4. Our Relationship With You
          <br />
          ‍This Agreement and the deposit relationship do not create a fiduciary
          relationship.
        </p>
        ‍
        <p>
          {' '}
          6.5. Privacy Policy
          <br />
          ‍Our privacy policy is available at www.aeldra.com and is considered
          part of this Agreement.
        </p>
        ‍<p> 6.6. Rates, Fees &amp; Transaction Limits </p>
        <p> 6.6.1 Rate Information &amp; Fees</p>
        <table className="table table-dashed" cellPadding={0} cellSpacing={0}>
          <tr>
            <td colSpan={2}>Minimum Average Monthly Balance</td>
            <td>$49 to avoid your account* being closed*</td>
          </tr>
          <tr>
            <td colSpan={2}>Minimum Transaction Requirement</td>
            <td>No Requirement</td>
          </tr>
          <tr>
            <td rowSpan={3}>Interest Rate</td>
            <td>On balances between $0 and $5,000</td>
            <td>0.02% / 0.02% APY</td>
          </tr>
          <tr>
            <td>On balances between $5,001 and $15,001</td>
            <td>0.02% / 0.02% APY</td>
          </tr>
          <tr>
            <td>On balances greater than $15,001</td>
            <td>0.02% / 0.02% APY</td>
          </tr>

          <tr>
            <td colSpan={2}>Monthly Fees</td>
            <td>No Monthly Fees</td>
          </tr>
          <tr>
            <td colSpan={2}>Account* Opening Fee</td>
            <td>No Fee</td>
          </tr>
          <tr>
            <td colSpan={2}>Transaction Fees</td>
            <td>No Fees per Transaction</td>
          </tr>
        </table>
        <p>
          effective for accounts opened on or after January 1, 2022. Accounts
          Opened before January 1, 2022 have no minimum balance requirement.
        </p>
        <p>6.6.2 Transaction Limits</p>
        <p>These limits apply to all accounts.</p>‍
        <p>
          Certain members may be eligible for higher transfer limits based on
          Checking Account history, at our discretion. If you need different
          limits, please contact Aeldra Concierge to have your existing limits
          reviewed, e.g. students may request higher limits for tuition payments
        </p>
        <p className="bold">
          These limits may be unavailable or delayed from time to time due to
          risk-based restrictions, compliance requirements, scheduled
          maintenance, changes to our services, unforeseen circumstances, or
          outages.
        </p>
        <table className="table table-dashed" cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>Transaction Limit</th>
              <th>Transaction Dollar Limit</th>
              <th>Daily Dollar Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4} className="bold">
                Card Transactions
              </td>
            </tr>

            <tr>
              <td>ATM/Cash Withdrawal</td>
              <td>5 Transactions per day</td>
              <td>$500</td>
              <td>$2,000 </td>
            </tr>
            <tr>
              <td>Card Purchases (Signature and PIN)</td>
              <td>No limit to the number of times per day</td>
              <td>$1,000</td>
              <td>$2,000</td>
            </tr>

            <tr>
              <td colSpan={4}>Deposits</td>
            </tr>

            <tr>
              <td>Wire Transfer</td>
              <td>5 Transactions per day</td>
              <td>N/A</td>
              <td>$50,000</td>
            </tr>
            <tr>
              <td>
                Direct deposits or ACH Transfers initiated from an outside
                financial institutio
              </td>
              <td>5 Transactions per day</td>
              <td>N/A</td>
              <td>$50,000</td>
            </tr>

            <tr>
              <td>Cash Loads Available within two (2)hours after loading</td>
              <td>No limit to the number of times per calendar day</td>
              <td>N/A</td>
              <td>$50,000</td>
            </tr>

            <tr>
              <td colSpan={4}>Bill Payment</td>
            </tr>

            <tr>
              <td>Bill Payment</td>
              <td>$500 per transaction</td>
              <td>$2,000 per month</td>
              <td>Varies by *; Call Aeldra Concierge</td>
            </tr>

            <tr>
              <td colSpan={4}>
                Aeldra Pay - Aeldra Account to Aeldra Account Transfer
              </td>
            </tr>

            <tr>
              <td>Aeldra Pay to Other Accounts</td>
              <td>5 Transactions per day</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
            <tr>
              <td>Aeldra Pay from Other Accounts</td>
              <td>5 Transactions per day</td>
              <td>$1,000</td>
              <td>$5,000</td>
            </tr>

            <tr>
              <td colSpan={4}>ACH Withdrawals</td>
            </tr>
            <tr>
              <td>ACH Debits initiated by other financial institutions</td>
              <td>Varies by Account*; Call Aeldra Concierge</td>
              <td>$10,000</td>
              <td>$10,000</td>
            </tr>
            <tr>
              <td>
                ACH Credits initiated from the Aeldra App to another bank
                account*
              </td>
              <td>Varies by Account*; Call Aeldra Concierge</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    question: '7. General Rules Governing Deposit Accounts*',
    answer: (
      <>
        <p>
          The Checking Account and your obligations under this Agreement may not
          be assigned. We may transfer our rights under this Agreement. Use of
          the Checking Account is subject to all applicable rules and customs of
          any clearing house or other association involved in transactions. We
          do not waive our rights by delaying or failing to exercise them at any
          time. If any provision of this Agreement shall be determined to be
          invalid or unenforceable under any rule, law, or regulation of any
          governmental agency, local, state, or federal, the validity or enforce
          ability of any other provision of this Agreement shall not be
          affected. This Agreement will be governed by the laws of the State of
          Delaware except to the extent governed by federal law.
        </p>
        ‍<p>7.1. Deposits to the Account</p>
        <p>
          Please refer to the section Rates, Fees and Limits for details on
          Deposit Methods and Limits for your Checking Account.
        </p>
        ‍<p>7.2. No Cash, Paper Checks or Foreign Currency</p>
        <p>
          ‍We are not liable for any deposits, including cash, lost in the mail,
          lost in transit, or not received by us. Cash – We do not accept
          deposits made in cash. If you mail a cash deposit, the cash will be
          sent back to the address we have for you on file. Paper Checks –
          Personal checks, cashier’s checks, and money orders may not be
          deposited by mail. All checks and money orders sent to us for deposit
          will be returned unless the full amount may be applied towards a
          negative balance, in which case the check or money order may or may
          not be deposited to the Account at our discretion.Foreign Currency –
          We do not accept deposits in foreign currency. Any deposits received
          in foreign currency, cash or check, will be sent back to the address
          we have for you on file.
        </p>
        <p>7.3. Funds Availability</p>
        <p>
          Please refer to Section “GENERAL FUNDS AVAILABILITY” for additional
          information.
        </p>
        <p>7.4. Preauthorized Transfers</p>
        <p>
          Your Checking Account number and bank routing number can be used for
          preauthorized direct debits (“ACHDebits”) from merchants, Internet
          service or other utility service providers (“Merchants”) and for the
          purpose of initiating direct deposits to your Checking Account. These
          transfers will be processed under the Operating Rules ofthe National
          Automated Clearing House Association (“NACHA”) and you agree to comply
          with the NACHA rules. Detailed information regarding preauthorized
          transfers is available in Section labeled “Electronic Funds Transfer
          Disclosure and Special Terms and Conditions“.NOTE: The recipient’s
          name on any direct deposit(s) we receive must match the name of the
          Aeldra Accountholder.Any direct deposits received in a name other than
          the name registered to the Checking Account will be returned tothe
          originator.
        </p>
        <p>7.5. Problems That Could Occur with Deposits</p>
        <p>
          Overpayments and Reversals. If funds are deposited or transferred into
          your Checking Account by mistake orotherwise, we may correct the
          situation by deducting the amount of the deposit from your Checking
          Account without prior notice to you. If there are not enough funds in
          your Checking Account at that time, your CheckingAccount could become
          overdrawn. See the “No Overdrafts” and “Right to Set Off” sections
          below for more information about what could occur if your Checking
          Account has a negative balance.
        </p>
        <p>7.6. No Overdrafts</p>
        <p>
          You are not permitted to overdraw your Checking Account. If the
          available balance in your Checking Account is not sufficient to cover
          any payment or withdrawal you have authorized, we can refuse to
          process the payment or withdrawal. If your Checking Account balance
          becomes negative for any reason, you must make a deposit immediately
          to cover the negative balance. If your Checking Account has a negative
          balance for an extended period of time and you have another account
          with us, we reserve the right to exercise our right to set off. See
          the “Right to Set Off” section below for details. If your Checking
          Account has a negative balance for ninety (90) calendar days ormore it
          will be closed.
        </p>
        <p>7.7. Right to Set Off</p>
        <p>
          If your Checking Account balance becomes and remains negative for
          fifteen (15) days, we can use the funds in any of your accounts to
          repay the amount owed on the negative balance Checking Account without
          further notice to or demand on you. This means, we have the right to
          set-off any liability, direct or contingent, past, present or future
          that you owe against any account you have with us. Further, you grant
          us a lien on and security interest in the fundson deposit in each of
          your account(s) as security for all of your liabilities and
          obligations to us, now or in the future.We will notify you if we have
          exercised our right to set off.
        </p>
        <p>7.8. Legal Processes Affecting Accounts</p>
        <p>
          If legal action such as a garnishment, levy or other state or federal
          legal process (“Legal Process”) is brought against your Checking
          Account, we may refuse to permit (or may limit) withdrawals or
          transfers from your Checking Account until the Legal Process is
          satisfied or dismissed. Regardless of the terms of such garnishment,
          levy or other state or federal process, we have first claim to any and
          all funds in your Checking Account. We will not contest on your behalf
          any such Legal Process and may take action to comply with such Legal
          Process as we determine to be appropriate in the circumstances without
          liability to you, even if any funds we may be required to pay out
          leaves insufficient funds to pay a transaction that you have
          authorized. Payment is made after satisfying any fees, charges or
          other debts owed to us. You agree that you are responsible for any
          expenses, including legal expenses and feeswe incur due to any Legal
          Process on your Checking Account. We may charge these expenses to your
          Checking Account. You will in demnify us for any losses if we do this.
        </p>
        <p>7.9. Account Holds, Suspensions, and Closures</p>
        <p>
          ‍Aeldra may suspend or close your Account for any reason with or
          without notice if we, at any time, believe a member is using the
          Checking Account and/or associated Aeldra Mastercard® Debit Card for
          fraudulent or illegal purposes, or such member otherwise presents an
          undue risk to Aeldra as determined in our sole discretion. Such
          reasons include, but are not limited to:
        </p>
        <p>
          a) Your use conflicts with any federal, state or local law, rule or
          regulation, including federal foreign asset controland sanction rules
          and anti-money-laundering rules, or with our policies adopted to
          assure that we comply with those laws, rules or regulations.
        </p>
        <p>
          b) We receive a court order or other legal request to suspend or close
          your account.
        </p>
        <p>
          c) Your non-fraudulent actions nonetheless violate any part of the
          Deposit Account Agreement.If our monitoring of the Checking Account
          detects any such activity, the Account funds will be subject to a hold
          pending review of the activity by Aeldra. Aeldra may require you and
          other parties to the activity to produced ocuments and/or other
          materials evidencing the validity of the activity. Funds on deposit in
          any Account are subject to hold at the Aeldra’s discretion until the
          source of such funds and/or the activity is properly verified.
        </p>
        ‍<p>7.10. Amendment and Cancellation</p>
        <p>
          ‍We may amend or change the terms and conditions of this Agreement at
          any time by posting the amended Agreement on aeldra.com, and any such
          amendment shall be effective upon such posting to that Website. The
          current Agreement is available at aeldra.com. You will be notified of
          any change in the manner provided by applicable law prior to the
          effective date of the change. However, if the change is made for
          security purposes, wecan implement such change without prior notice.
          We may cancel or suspend your Checking Account or this Agreement at
          any time.
        </p>
        You may cancel this Agreement by calling 1-844-333-3101 to close your
        Checking Account. Your termination of this Agreement will not affect any
        of our rights or your obligations arising under this Agreement prior to
        termination. If your Checking Account is cancelled, closed or terminated
        for any reason, you may request the unused balance be returned to you
        via check sent to the mailing address we have in our records. For
        security purposes, you may berequired to supply identification and
        address verification documentation prior to issuing a refund check.
        Allow fourteen (14) days for processing and mailing of the refund check.
        In the event the program is cancelled, closed, orterminated, we will
        send you prior notice, in accordance with applicable law. Specific
        information and instructions, including how to receive any remaining
        Checking Account balance, will be in the notice. We reserve the right
        torefuse to return any unused balance amount less than $1.00. ‍
        <p>7.11. Account Dormancy and Escheatment</p>
        <p>
          An account that is inactive for a period of time may be considered
          dormant and is subject to escheatment. Each state has varying laws as
          to when an account is subject to escheatment and we may be required to
          send the balance in your Checking Account to the state of your last
          known address. We will make all reasonable efforts tocontact you
          before transferring the remaining balance of your Checking Account to
          the applicable state. For aChecking Account with an international
          address, the funds will be transferred to the State of Delaware.
        </p>
        <p>7.12. Statements</p>
        <p>
          Electronic statements are available to view and print in the Aeldra
          App. Checking Account statements are considered to be correct.
          Carefully review your statements each statement period and notify us
          of any errors within sixty (60) days of your statement becoming
          available. You also have a right to obtain a sixty (60) day history
          yourChecking Account transactions by calling us at 1-844-333-3101,
          sending us an email at concierge@aeldra.com.
        </p>
        <p>7.13. Special Rules for New Accounts</p>
        <p>
          ‍Checking Accounts open less than thirty (30) days will be limited in
          functionality. You may not be eligible to use certain features such as
          Aeldra Pay, and Aeldra Pay Bills until the Checking Account has been
          open for at least thirty (30) days. In addition, certain transfer
          limits may be lower during this time as indicated in Section "Deposits
          to the Account”, Section Electronic Banking Services” and Section
          “ELECTRONIC FUNDS TRANSFER DISCLOSURE AND SPECIAL TERMS AND
          CONDITIONS”.
        </p>
        <p>7.14. Limitation on Amount of Transactions.</p>
        <p>
          Depending on transaction activity and other factors, we may adjust
          transaction limits on your Account.
        </p>
        <p>7.15. Wire Transfer.</p>
        <p>
          If enabled by Bank for your Account, you may be able to send or
          receive a wire transfer request. If you originate a wire transfer
          request, you agree that the Fedwire Funds Service or any other
          clearing and settlement system or intermediary bank may be used, in
          our sole discretion. Federal Reserve Board Regulation J is the law
          that coverstransactions made over the Fedwire Funds Service. You
          hereby agree to be bound by Regulation J or any other funds transfer
          system rule, to the extent inconsistent with this Agreement. When you
          originate any funds transfer request, you identify by name and number
          a beneficiary financial institution, an intermediary financial
          institution,or a beneficiary, Bank and every receiving beneficiary
          financial institution may rely on all identifying number to make
          payment. Bank may rely on the number even if it identifies a financial
          institution, person or account otherthan the one named. The Bank will
          process the wire transfer request based solely on the information you
          provide tous and expressly disclaims any obligation to review any such
          request for errors or inconsistent or duplicative information. It is
          your obligation to verify the information you provide to us including,
          but not limited to, the identity and relationship of the receiving
          party, account number, and routing number. The Bank is not responsible
          for any loss or damage you incur from your request to send a wire or
          electronic transfer. Please be advised that wire transfer requests may
          not be recalled or amended once they have been sent to the Bank. Wire
          transfer requests will only be accepted and processed if you have
          sufficient and available funds in your Account at the time there quest
          is received and processed. The Bank reserves the right to reject or
          cancel any wire transfer at our sole discretion and will not be held
          liable for any losses or damages that may arise due to our right to
          cancel or reject the wire transfer.{' '}
        </p>
        <p>7.15 ACH Transactions:</p>
        <p>
          You authorize us to honor, accept, execute, and otherwise act on any
          electronic funds transfer (“EFT”) request,including by ACH, or other
          communication transmitted by you and accepted by us for any Account.
          However, wereserve the right, subject to applicable law, to hold funds
          pending settlement or for such period of time as we deemnecessary, in
          our sole discretion, to cover items which may be returned unpaid. For
          EFTs through ACH, you authorize us to originate ACH transfers on your
          behalf, which will result in a debit or credit to one of your Accounts
          and a corresponding credit or debit to the receiving account. If ACH
          debit entries are enabled by Bank on your Account, you are limited to
          debiting and pulling funds to your Account only from accounts you own
          at other financial institutions that you have linked to your Account.
          You authorize us to validate your ownership of such other accounts
          through any commercially reasonable means, including through
          third-party service providers. Inconnection with debit entries, we
          may, subject to the Nacha Operating Rules, hold funds to make sure
          that the item will not be returned unpaid before we will credit your
          Account. If the ACH transaction is returned for any reasonand the
          payment has been credited to your Account, you authorize us to debit
          your Account, in whole or in part, forthe amount of the returned item
          and for any applicable returned item fee which may be facilitated by
          us. You agree to initiate all ACH transactions described herein in
          accordance with applicable law and in compliance with the Nacha
          Operating Rules. You agree we may audit your compliance with this
          Agreement and the Nacha Operating Rules and may terminate or suspend
          this Agreement for any identified breach thereof. You acknowledge that
          we16are entitled to rely on information provided by you in acting on
          any EFT or other communication. If you originate an EFT and you
          identify by name and number a beneficiary financial institution, an
          intermediary financial institution, ora beneficiary, we and every
          other receiving or beneficiary financial institution may conclusively
          rely on theidentifying number to execute the EFT request. We may rely
          on the number even if it identifies a financial institution, person,
          or account other than the one named. If we do not receive such
          payment, we are entitled to arefund from you in the amount credited to
          your Account and the party originating such payment will not be
          considered to have paid the amount so credited. At the Bank’s
          discretion, the amount of funds that can be transferred from your
          Account per day may be limited. Limits may change at any time without
          notice. At the Bank’s discretion, the amount of funds that can be
          transferred from your account per day may be limited. Limits may
          change at any time without notice.
        </p>
        <p>7.16 Deposit Limits:</p>
        <p>
          As set forth below, we use customer approval levels to determine
          limits on the dollar amount of deposits into your Account. Approval
          levels are determined by a combination of criteria, which include
          criteria like account age,transaction history, and other factors. The
          customer approval levels set forth below are designed to provide you
          with an understanding of frequency and dollar amount limits on
          specified transactions, but we may also impose additional limits.
          These additional limits are designed to be flexible in order to
          protect the security and integrity ofthe service and Accounts, as well
          as you and all other users of the service.
        </p>
        <table className="table table-dashed" cellPadding={0} cellSpacing={0}>
          <tbody>
            <tr>
              <td>Method of Deposit</td>
              <td>Limitations</td>
            </tr>
            <tr>
              <td>ACH or Wire Transfers to theAccount</td>
              <td>Limit of $50,000 per day*</td>
            </tr>
          </tbody>
        </table>
        <p>
          *You may be eligible for higher transfer limits based on Account
          history, at the discretion of Aeldra.
        </p>
        <p>7.17 Withdrawal and Transfer Limits:</p>
        <p>
          You may withdraw or transfer all or any part of the available Account
          balance at any time, subject to the transaction limitations and by
          using any of the methods set forth below. Before permitting a
          withdrawal or other transaction, we may request that you provide us
          with additional information or documentation that we deem necessary to
          confirm your identity or to prevent illegal activity. We may refuse
          the transaction if you do not comply with our request. You cannot
          withdraw or otherwise transfer funds by check, and we do not permit
          you to draft checks drawn on your Account. As set forth below, we use
          customer approval levels to determine limits on the dollar amount of
          EFTs/transfers related to your Account. Approval levels are determined
          by a combination of criteria,which include criteria like Account age,
          transaction history, and other factors. The customer approval levels
          set forth below are designed to provide you with an understanding of
          frequency and dollar amount limits on specified transactions, but we
          may also impose additional limits. These additional limits are
          designed to be flexible in orderto protect the security and integrity
          of the service and Accounts, as well as you and all other users of the
          service.
        </p>
        <table className="table table-dashed" cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>Transaction Limit</th>
              <th>Transaction Dollar Limit</th>
              <th>Daily Dollar Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4}>Card Transactions</td>
            </tr>
            <tr>
              <td>ATM/Cash Withdrawal</td>
              <td>5 Transactions per day</td>
              <td>$500</td>
              <td>$2,000 </td>
            </tr>
            <tr>
              <td>Card Purchases (Signature and PIN)</td>
              <td>No limit to the number of times per day</td>
              <td>$1,000</td>
              <td>$2,000</td>
            </tr>
            <tr>
              <td colSpan={4}>Bill Payment</td>
            </tr>

            <tr>
              <td>Bill Payment</td>
              <td>$500 per transaction</td>
              <td>$2,000 per month</td>
              <td>Varies by Account; Call Aeldra Concierge</td>
            </tr>
            <tr>
              <td colSpan={4}>
                Aeldra Pay - Aeldra Account to Aeldra Account Transfer
              </td>
            </tr>
            <tr>
              <td>Aeldra Pay to Other Accounts</td>
              <td>5 Transactions per day</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
            <tr>
              <td>Aeldra Pay from Other Accounts</td>
              <td>5 Transactions per day</td>
              <td>$1,000</td>
              <td>$5,000</td>
            </tr>
            <tr>
              <td colSpan={4}>ACH Withdrawals</td>
            </tr>
            <tr>
              <td>ACH Debits initiated by other financial institutions</td>
              <td>Varies by Account; Call Aeldra Concierge</td>
              <td>$10,000</td>
              <td>$10,000</td>
            </tr>
            <tr>
              <td>
                ACH Credits initiated from the Aeldra App to another bank
                account
              </td>
              <td>Varies by Account; Call Aeldra Concierge</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
  },
  {
    question: '8. Using the Aeldra Mastercard^® Debit Card^',
    answer: (
      <>
        <p>
          Indian residents will be restricted from debit card usage until the
          customer is physically in the U.S. Customers will have to follow
          applicable Aeldra procedures which will include providing
          documentation that establishes physical presence in the U.S. and by
          contacting Aeldra Concierge to remove the usage restriction. Please
          call us on +1-844-333-3101 for more information. You acknowledge and
          agree that the funds accessible through use of the Card are limited to
          the available funds in your Checking Account. You agree to sign the
          back of the Card immediately upon receipt. The expiration date of the
          Card is identified on the Card. The Card is the property of the Issuer
          and must be surrendered upon demand. The Card is nontransferable, and
          it may be cancelled, repossessed, or revoked at any time without prior
          notice subject to applicable law.
        </p>
        <p>8.1. Activate the Card</p>
        <p>
          ‍You must activate the Card before it can be used. You may activate it
          by following the email instructions you receive upon opening an
          account, or by calling 1-844-333-3101 or through the Aeldra App. You
          will need to provide personal information in order for us to verify
          your identity. Certain functionality outlined in this Agreement may be
          restricted prior to activating the Card, for details see Section
          “Deposits to the Account”, “Electronic Banking Services” and Section
          “ELECTRONIC FUNDS TRANSFER DISCLOSURE AND SPECIAL TERMS AND
          CONDITIONS”
        </p>
        <p>8.2. Personal Identification Number</p>
        <p>
          ‍You will not receive a Personal Identification Number (“PIN”) with
          the Card. However, you will be advised of your default PIN and you
          will be able to change through the Aeldra App or by calling
          1-844-333-3101. See the activation instructions in the “Activate The
          Card” section. You should not write or keep your PIN with your Card.
          Never share your PIN with anyone. When entering your PIN, be sure it
          cannot be observed by others and do not enter your PIN into any
          terminal that appears to be modified or suspicious. If you believe
          that anyone has gained unauthorized access to your PIN, you should
          advise us immediately following the procedures in the section labeled
          “Your Liability for Unauthorized Transfers.”
        </p>
        <p>8.3. Authorized Card or Checking Account Users</p>
        <p>
          ‍You are responsible for all authorized transactions initiated and
          fees incurred by use of the Card or Checking Account. If you permit
          another person to have access to your Card, Card number(s), Checking
          Account number(s) or PIN, we will treat this as if you have authorized
          such use and you will be liable for all transactions and fees incurred
          by those persons. You are wholly responsible for the use of your
          Checking Account according to the terms and conditions of this
          Agreement.
        </p>
        <p>8.4. Secondary Cardholder</p>
        <p>‍You may not request an additional Card for another person.</p>‍
        <p>8.5. Your Representations and Warranties</p>
        <p>
          ‍By activating the Card or by retaining, using or authorizing the use
          of the Card provided with the Checking Account, you represent and
          warrant to us that: (i) you are at least 18 years of age (or older if
          you reside in a state where the majority age is older); (ii) the
          personal information that you provide to us in connection with the
          Checking Account is true, correct and complete; (iii) you received a
          copy of this Agreement and agree to be bound by and to comply with its
          terms; (iv) Indian residents will be restricted from debit card usage
          until the customer is physically in the U.S. Customers will have to
          follow applicable Aeldra procedures which will include providing
          documentation that establishes physical presence in the U.S. and by
          contacting Aeldra Concierge to remove the usage restriction.; and (v)
          you accept the Card.
        </p>
        <p>8.6. Cash Access and Transaction Limitations</p>
        <p>
          ‍With your PIN, you may use the Card to obtain cash from your Checking
          Account at any Automated Teller Machine (“ATM”) or any Point-of-Sale
          (“POS”) device, as permissible by a merchant, that bears the
          Mastercard®, Interlink®, Plus®, or Maestro® Acceptance Marks. All ATM
          transactions are treated as cash withdrawal transactions. You may use
          the Card^ at an ATM and withdraw funds at a participating bank (“Over
          The Counter” or “OTC” withdrawals). ATM and OTC cash withdrawals,
          funds withdrawn at a POS, and purchases are all subject to the maximum
          amount that can be spent on the Card per day. Please refer to the
          Rates, Fees & Limits section for itemized cash access and limits for
          your Checking Account: You may use the Card to purchase or lease goods
          or services everywhere Mastercard® debit, Interlink or Maestro Cards
          are accepted as long as you do not exceed the available balance of
          your Checking Account. Some merchants do not allow customers to
          conduct split transactions where the Card is used as partial payment
          for goods and services and the remainder of the balance is paid with
          another form of legal tender. If you wish to conduct a split
          transaction and it is permitted by the merchant, you must tell the
          merchant to charge only the exact amount of funds available in the
          Checking Account to the Card. You must then arrange to pay the
          difference using another payment method. Some merchants may require
          payment for the remaining balance in cash. If you fail to inform the
          merchant that you would like to complete a split transaction prior to
          swiping the Card, the Card is likely to be declined. If you use the
          Card at an automated fuel dispenser (“pay at the pump”), the
          transaction may be pre-authorized for an amount up to $75.00 or more.
          If the Card is declined, even though you have sufficient funds
          available, you should pay for your purchase inside with the cashier.
          If you use the Card at a restaurant, a hotel, for a car rental
          purchase, or for similar purchases, the transaction may be
          pre-authorized for the purchase amount plus up to 20% or more to
          ensure there are sufficient funds available to cover tips or
          incidental expenses incurred. A pre-authorization will place a “hold”
          on those available funds until the merchant sends us the final payment
          amount of your purchase. Once the final payment amount is received,
          the pre-authorized amount on hold will be removed. It may take up to
          seven (7) days for the hold to be removed. During the hold period, you
          will not have access to the pre-authorized amount. All transactions
          relating to car rentals may result in a hold for that amount of funds
          for up to sixty (60) days. If you use the 16-digit Card number without
          presenting the Card^ (such as for a mail order, telephone, or Internet
          purchase), the legal effect will be the same as if you used the Card^
          itself. For security reasons, we may limit the amount or number of
          transactions you can make with the Card. The Card cannot be redeemed
          for cash. You may not use the Card for any illegal online gambling or
          any other illegal transaction. Each time you use the Card, you
          authorize us to reduce the value available in the Checking Account by
          the amount of the transaction and any applicable fees. You are not
          allowed to exceed the available amount in the Checking Account through
          an individual transaction or a series of transactions. Nevertheless,
          if a transaction exceeds the balance of the funds available in the
          Checking Account, you shall remain fully liable to us for the amount
          of the transaction and any applicable fees. See these provisions for
          additional details if your Checking Account balance becomes negative:
          “No Overdrafts,” and “Right to Set Off.”
        </p>
        <p>
          You do not have the right to stop payment on any purchase or payment
          transaction originated by use of the Card. If you authorize a
          transaction and then fail to make a purchase of that item as planned,
          the approval may result in a hold for that amount of funds for up to
          sixty (60) days.
        </p>
        <p>8.7. Non-Mastercard® Debit Transactions</p>
        <p>
          ‍New procedures are in effect that may impact you when you use the
          Card at certain merchant locations. In the past, transactions have
          been processed as a Mastercard® debit transactions unless you entered
          a PIN. Now, if you do not enter a PIN, transactions may be processed
          as either a Mastercard® debit, Interlink or as a Maestro transaction.
        </p>
        Merchants are responsible for and must provide you with a clear way of
        choosing how to make a Mastercard® debit transaction if they support the
        option. Please be advised that should you choose to use the Interlink or
        Maestro network when making a transaction without a PIN, different terms
        may apply. Certain protections and rights applicable only to Mastercard®
        debit transactions as described in this Agreement will not apply to
        transactions processed on the Interlink or Maestro network. Please refer
        to the section labeled “Your Liability for Un-authorized Transfers” for
        a description of these rights and protections applicable to Mastercard®
        debit and non-Mastercard® debit transactions.
        <p>
          To initiate a Mastercard® debit transaction at a point-of-sale, swipe
          the Card through the POS terminal, sign the receipt, or provide your
          16-digit Card number for a mail order, telephone, or Internet
          purchase. To initiate a non-Mastercard® debit transaction at the POS,
          enter your PIN at the POS terminal or provide your 16-digit Card
          number after clearly indicating a preference to route your transaction
          as a non-Mastercard® debit transaction for certain bill payment, mail
          order, telephone, or Internet purchases.
        </p>
        <p>8.8. Transactions Made In Foreign Currencies</p>
        <p>
          ‍If you obtain funds or make a purchase in a currency other than the
          currency in which the Card was issued, the amount deducted from the
          available balance of your Checking Account will be converted by
          Mastercard® into an amount in the currency of the Card. The exchange
          rate between the transaction currency and the billing currency used
          for processing international transactions is a rate selected by
          Mastercard® from the range of rates available in wholesale currency
          markets for the applicable central processing date, which may vary
          from the rate Mastercard® itself receives, or the government-mandated
          rate in effect for the applicable central processing date.
        </p>
        <p>8.9. Receipts</p>
        <p>
          ‍You should get a receipt at the time you make a transaction using the
          Card. You agree to retain, verify, and reconcile your transactions and
          receipts.
        </p>
        <p>8.10. Card Replacement</p>
        <p>
          ‍If you need to replace the Card for any reason except at Card^
          expiration, you can do so by calling 1-844-333-3101 or through the
          Aeldra App. You will be required to provide personal information which
          may include your Checking Account number, 16-digit Card number, full
          name, transaction history, copies of acceptable documentation, etc.
        </p>
        <p>8.11. Card Expiration</p>
        <p>
          ‍The Card will expire no sooner than the date printed on it. You will
          not be able to use the Card after the expiration date; however, a
          replacement Card will be mailed to you. If you need a Card replacement
          for any reason other than the Card’s expiration, you may request one
          at any time by following the procedures in the section labeled “Card
          Replacement.”
        </p>
      </>
    ),
  },
  {
    question: '9. Electronic Banking* Services',
    answer: (
      <>
        <p>9.1. Bill Payments</p>
        <p>
          ‍With your Checking Account, you will have access to the Bill Payment
          feature (the “Pay Bills”) through the Aeldra App which allows you to
          authorize us to make bill payments on your behalf to third parties.
          You will have access to the Bill Payment feature once your Checking
          Account has been funded i.e. on the calendar day that your first
          successful deposit is made to your Checking Account.
        </p>
        ‍
        <p>
          To initiate a bill payment, you must provide the name and mailing
          address of each individual or company you wish to pay. Once a payment
          is authorized, the payment amount will be immediately deducted from
          your Checking Account balance. Payments made using the Pay Bills take
          the form of a paper check sent to the payee on your behalf using
          standard U.S. Postal Service mail. Please allow three to nine (3-9)
          business days for delivery of the check. Payments can only be sent to
          addresses located within the fifty (50) states of the U.S. Pay Bills
          payments are processed daily by 8 AM MST. Pay Bills payments initiated
          after this time will be processed by 8 AM MST the next business day.
          Aeldra reserves the right to refuse to process payments to any
          individual or company. If the decision is made to refuse a payment,
          Aeldra will notify you on or before the next business day.
        </p>
        <p>
          These are the limits when using Pay Bills. Please refer to section 6.6
          for details.
        </p>
        <p>
          Pay Bills payments may be refused or returned by the individual or
          company to whom the payment was issued. The determination to accept
          this method of payment is at the discretion of the recipient. The U.S.
          Postal Service may also return payments in cases of expired or invalid
          addresses. If the Pay Bills payment is returned for any reason, the
          payment will be voided and the full amount credited to your Checking
          Account the next business day. If your Checking Account is closed when
          the Pay Bills payment is returned to us, we will issue you a check and
          mail it to the address we have on file for you.
        </p>
        <p>
          Uncashed Pay Bills Payments: Uncashed Pay Bills payments are voided
          after 180 days. Funds from voided checks will be credited to your
          Checking Account by 8 AM MST on the next available business day.
        </p>
        <p>
          CANCELLING A PAY BILLS PAYMENT: You may cancel a single Pay Bills
          payment as long as it has not been presented for payment by calling
          1-844-333-3101. Funds from any cancelled check will be credited to
          your Checking Account by 8 AM MST on the next available business day.
        </p>
        <p>
          LIABILITY FOR FAILURE TO STOP PAYMENT OF A PAY BILLS PAYMENT: If you
          request cancellation of a Pay Bills payment within three (3) three
          business days or more before it is scheduled to be made, and Aeldra
          does not cancel it in time, Aeldra will be liable for your losses or
          damages.
        </p>
        <p>9.2. Aeldra Pay Transfers</p>
        <p>
          ‍Subject to the terms of this Agreement, your Checking Account
          includes access to the Aeldra Pay Transfer Service (“Aeldra Pay”) via
          the Aeldra App. This service allows you to send money to other Aeldra
          members as well as receive money from other Aeldra members (“Aeldra
          Pay Transfers”). You may send money to another Aeldra member by
          inputting the intended recipient’s email address, mobile phone number,
          or unique Aeldra Username (the “Username”) and the Aeldra Pay Transfer
          dollar amount you would like to send. Aeldra Pay Transfers will be
          applied to the Checking Account associated with the recipient’s email
          address, mobile phone number, or Username entered by the sender.
          Approved Aeldra Pay Transfers are automatically debited from the
          sender’s Checking Account and deposited to the recipient’s Checking
          Account within seconds.
        </p>
        <p>
          You will not have access to Aeldra Pay to send money until your
          Checking Account has been funded i.e. from the calendar day that your
          first successful deposit is made to your Checking Account.
        </p>
        <p>
          Your continued access to Aeldra Pay is determined by Aeldra in its
          sole discretion based on the history of any Aeldra-branded accounts
          you have, your use of Aeldra Pay in the past, direct deposit history
          and direct deposit amounts and other risk-based factors. Aeldra may
          suspend or terminate your use of Aeldra Pay at any time and without
          prior notice to you. If you violate the terms of Aeldra Pay, such
          actions may be used as a basis to terminate your Checking Account.
        </p>
        <p>
          Please note: The intended recipient must have an open Aeldra Checking
          Account in order to receive an Aeldra Pay Transfer(s). If you wish to
          send money to a non-Aeldra member, your intended payee must sign-up
          and be approved for an Aeldra Checking Account* prior to receiving
          your payment.
        </p>
        <p>
          By using the Aeldra Pay feature, you agree that you will not post,
          transmit, distribute, or disseminate through any material that: (a) is
          false, misleading, unlawful, obscene, indecent, lewd, pornographic,
          defamatory, libelous, threatening, harassing, hateful, abusive, or
          inflammatory; (b) encourages conduct that would be considered a
          criminal offense or gives rise to civil liability or violates these
          terms and the Agreement; or (c); in our sole judgment, is
          objectionable, restricts or inhibits any person or entity from using
          or enjoying any portion of Aeldra Pay, or which may expose Aeldra, the
          Bank, its affiliates or its customers to harm, reputational risk, or
          liability of any nature.
        </p>
        <p>Using the Aeldra Pay Transfer Service</p>
        <p>
          {' '}
          To send an Aeldra Pay Transfer(s), login to the Aeldra App and select
          “Aeldra Pay” and enter the intended recipient’s email address, name,
          account number or the recipient’s mobile phone number.
        </p>
        <p>
          You must make sure the recipient’s email address, name, account number
          or the recipient’s mobile phone number is entered accurately to ensure
          the Aeldra Pay Transfer is sent to the correct recipient. The
          obligation to pay the Aeldra Pay Transfer will not be excused by an
          error in the information entered.
        </p>
        <p>
          By using Aeldra Pay, you are representing that you are the owner of
          the mobile phone number or email address listed on your Checking
          Account. You consent to have the first and last name registered on
          your Checking Account associated with your email address, account
          number or mobile phone number. By doing so, your first and last name
          will be visible to other Aeldra members who search for you by name.
          This will assist the sending Aeldra member in verifying that you are
          the correct intended recipient of the Aeldra Pay Transfer.
        </p>
        Please note: The email address, name, account number or the recipient’s
        mobile phone number entered during the Aeldra Pay Transfer may display
        in your transaction history and on your statement for your reference.
        Mobile Carrier Information: By registering a mobile phone number on your
        Checking Account, you are certifying that you are the owner of the
        mobile phone account or have the account owner’s permission to register
        the mobile phone number.
        <p>
          Message and data rates may apply to the use of the mobile device when
          using the Aeldra Pay Transfer service via the Aeldra App.
        </p>
        <p>Aeldra Pay Transfer Limits</p>
        <p>
          Aeldra Pay Transfers may be initiated at any time as long as the
          transfer request does not exceed the available balance in your
          Checking Account. Aeldra may refuse to process any transaction that
          exceeds any of the limits described below. If your Aeldra Pay Transfer
          is denied because your Checking Account balance is not sufficient, you
          are responsible for making alternate payment arrangements with the
          intended recipient. For limits and fees associated with Aeldra Pay
          Transfer(s), please refer to section 6.6.
        </p>
        <p>
          Canceling Aeldra Pay Transfers: Prior to sending an Aeldra Pay
          Transfer, the sender will be prompted to confirm the transaction. Once
          the Aeldra Pay Transfer is sent, it cannot be canceled. It is the
          sender’s responsibility to ensure the information is accurate prior to
          confirming the Aeldra Pay Transfer request.
        </p>
        <p>
          If you believe your Checking Account has been compromised or that an
          Aeldra Pay Transfer from your Checking Account was completed without
          your permission, please contact 1-844-333-3101 immediately. Please see
          Section II ELECTRONIC FUNDS TRANSFER DISCLOSURE AND SPECIAL TERMS AND
          CONDITIONS below, where your liability for unauthorized transfers from
          the Checking Account is explained.
        </p>
        <p>
          In case of questions related to Aeldra Pay, please call Aeldra
          Concierge Services at 1-844-333-3101.
        </p>
      </>
    ),
  },
  {
    question:
      '10. Electronic Funds Transfer Disclosure and Special Terms and Conditions',
    answer: (
      <>
        <p>
          Your Checking Account number and bank routing number can be used for
          preauthorized direct debits (“ACHDebit(s)”) from merchants, Internet
          service or other utility service providers (“Merchants”) and for the
          purpose of initiating direct deposits (“ACH Credits”) to your Checking
          Account.Note: Generally, the recipient’s name on any direct deposit(s)
          or ACH Credit(s) we receive must match the name onthe Checking
          Account. Any direct deposits received in a name other than the name
          registered to the Checking Account will be returned to the originator.
          Aeldra may in its discretion permit certain government transfers so
          longas one recipient on the transfer matches the name on the Checking
          Account (e.g., tax returns for jointly filed returns).
        </p>
        <p>10.1. Electronic Funds Transfer Services</p>
        <p>
          The following terms are used to describe Electronic Funds Transfer
          (“EFT”) services. “Automated credits” or “direct deposits” are
          deposits made to your Checking Account by electronic means. “Automated
          debits” and “automated payments” indicate payments authorized by you
          to be made from your Checking Account by electronic means.When you
          accept direct deposits or authorize automatic payments/debits or
          transfers to or from your Checking Account, you agree to these terms
          and conditions. Other relevant terms and conditions described else
          where in the Agreement also apply as long as they are consistent with
          Regulation E or Section II.
        </p>
        <p>10.2. Your Liability for Unauthorized Transfers</p>
        <p>
          ‍‍Contact us at once if you believe your Card, PIN or Checking Account
          number has been lost or stolen. Telephoning isthe best way to minimize
          your possible losses. If your Card, PIN or Checking Account number has
          been lost or stolen, or someone has transferred or may transfer money
          from your Checking Account without your permission,call
          1-844-333-3101. Under Mastercard U.S.A. Inc. Operating Regulations,
          your liability for unauthorized Mastercard® debit transactions on your
          Checking Account is $0.00 if you are not grossly negligent or
          fraudulent inthe handling of your Card. This reduced liability does
          not apply to certain commercial card transactions,transactions not
          processed by Mastercard® or to ATM transactions outside the U.S. You
          must notify us immediately of any unauthorized use.
        </p>
        <p>
          If the Mastercard® Zero Liability Rules do not apply, if you notify us
          within two (2) business days after you learn ofany unauthorized
          transactions, you may be liable for no more than $50.00 if someone
          used your Checking Account without your permission. If you do not
          notify us within two (2) business days after you learn of the loss or
          theft ofyour Card, Checking Account number or PIN and we can prove
          that we could have stopped someone from usingyour Checking Account
          without your permission if you had promptly notified us, you may be
          liable for as much as$500.00.
        </p>
        <p>
          Also, if you become aware of and/or your statement shows transactions
          that you did not make, notify us at once following the procedures
          stated in the section labeled “Information About Your Right to Dispute
          Errors“. If you donot notify us within sixty (60) days after you
          become aware of the transaction(s) and/or the statement was made
          available to you, you may not get back any of the value you lost after
          the sixty (60) days if we can prove that wecould have stopped someone
          from taking value if you had notified us in time. If a good reason
          (such as a long trip ora hospital stay) kept you from telling us, we
          will extend the time periods. If your Card, PIN or Checking Account
          number has been lost or stolen, we will close your Card and/or Aeldra
          Account to keep losses down and send you are placement Card and/or
          Checking Account number. (a) 10.9.
        </p>
        ‍<p>10.3. Contact in event of unauthorized transfer.</p>
        <p>
          If you believe your Card, PIN or Checking Account number has been lost
          or stolen., call 1-844-333-3101. You should also call the number
          listed above if you believe a transfer has been made using the
          information from your account without your permission.
        </p>
        <p>10.4. Business days</p>
        <p>
          For purposes of these disclosures, our business days are Monday
          through Friday U.S. Pacific Time. Holidays are not included.
        </p>
        <p>10. 5. Electronic Funds Transfer types and Limitations</p>
        <p>
          10.5.1 You may make cash with drawals and POS purchases, not to exceed
          the established limits for your Checking Account. See the sections
          labeled “Cash Access” and “Transaction Limitations” for details about
          the limits.
        </p>
        <p>10.5.2 Make electronic deposits to your account.</p>
        <p>
          10.5.3 You may arrange with another party, such as your employer or a
          government agency, to electronically deposit funds on a one-time or
          recurring basis directly to your Checking Account.
        </p>
        <p>
          10.5.4 You may transfer funds between Aeldra accounts whenever you
          request through the Aeldra App.
        </p>
        <p>
          10.5.5 You may use the Card to make purchases at merchants that accept
          the Card or to obtain cash at ATMs, and,subject to availability, cash
          back at POS terminals.
        </p>
        <p>
          10.5.6 You may authorize another party, such as a merchant, to make a
          one-time or recurring payment(s) using the Checking Account and bank
          routing numbers, directly from your Checking Account subject to the
          established limitations on with drawals and transfers to i) Pay for
          purchases or ii) Pay bills.
        </p>
        <p>
          10.5.7 Some of these services may not be available at all terminals.
          10.4.2 If your Checking Account is closed,blocked, or suspended for
          any reason, you will not be able to transact using your Card
          (including at an ATM).
        </p>
        <p>
          10.6 Electronic check conversion.Your Aeldra account does not offer or
          allow the use checks.
        </p>
        <p>
          10.7. Limitations on Transfers, Amounts and Frequency of Transactions
        </p>
        <table className="table table-dashed" cellPadding={0} cellSpacing={0}>
          <thead>
            <tr>
              <th>Transaction Type</th>
              <th>Transaction Limit</th>
              <th>Transaction Dollar Limit</th>
              <th>Daily Dollar Limit</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td colSpan={4}>Card Transactions</td>
            </tr>
            <tr>
              <td>ATM/Cash Withdrawal</td>
              <td>5 Transactions per day</td>
              <td>$500</td>
              <td>$2,000 </td>
            </tr>
            <tr>
              <td>Card Purchases (Signature and PIN)</td>
              <td>No limit to the number of times per day</td>
              <td>$1,000</td>
              <td>$2,000</td>
            </tr>
            <tr>
              <td colSpan={4}>Deposits</td>
            </tr>

            <tr>
              <td>Wire Transfer</td>
              <td>5 Transactions per day</td>
              <td>N/A</td>
              <td>$50,000</td>
            </tr>
            <tr>
              <td>
                Direct deposits or ACH Transfersinitiated from an outside
                financialinstitution
              </td>
              <td>5 Transactions per day</td>
              <td>N/A</td>
              <td>$50,000</td>
            </tr>
            <tr>
              <td>Cash Loads Available within two (2)hours after loading</td>
              <td>No limit to the number of times percalendar day</td>
              <td>N/A</td>
              <td>$50,000</td>
            </tr>
            <tr>
              <td colSpan={4}>Bill Payment</td>
            </tr>
            <tr>
              <td>Bill Payment</td>
              <td>$500 per transaction</td>
              <td>$2,000 per month</td>
              <td>Varies by Account; Call Aeldra Concierge</td>
            </tr>
            <tr>
              <td colSpan={4}>
                Aeldra Pay - Aeldra Account to Aeldra Account Transfer
              </td>
            </tr>
            <tr>
              <td>Aeldra Pay to Other Accounts</td>
              <td>5 Transactions per day</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
            <tr>
              <td>Aeldra Pay from Other Accounts</td>
              <td>5 Transactions per day</td>
              <td>$1,000</td>
              <td>$5,000</td>
            </tr>
            <tr>
              <td colSpan={4}>ACH Withdrawals</td>
            </tr>
            <tr>
              <td>ACH Debits initiated by other financial institutions</td>
              <td>Varies by Account; Call Aeldra Concierge</td>
              <td>$10,000</td>
              <td>$10,000</td>
            </tr>
            <tr>
              <td>
                ACH Credits initiated from the Aeldra App to another bank
                account
              </td>
              <td>Varies by Account; Call Aeldra Concierge</td>
              <td>$1,000</td>
              <td>$1,000</td>
            </tr>
          </tbody>
        </table>
        <p>
          10.7.1 Cut-off Time – The cut-off time for scheduling ACH transfers is
          mid night Pacific Time. Any transfer scheduled after the cut-off time
          will be treated as if it were scheduled on the next business day.
        </p>
        ‍<p>10.8 Fees Aeldra does not charge transaction fees.</p>
        <p>
          10.9 Confidentiality We may disclose information to third parties
          about your Checking Account, Card or the transactions you make:
        </p>
        <p>10.9.1 Where it is necessary for completing transactions;</p>
        <p>
          10.9.2 In order to verify the existence and condition of your Checking
          Account or Card for a third party, such as amerchant;
        </p>
        <p>
          10.9.3 In order to comply with government agency, court order, or
          other legal or administrative reporting requirements;
        </p>
        <p>10.9.4 If you consent by giving us your written permission;</p>
        <p>
          10.9.5 To our employees, auditors, affiliates, service providers, or
          attorneys as needed;
        </p>
        <p>10.9.6 As noted in the Aeldra Privacy Policy); or</p>
        <p>
          10.9.7 As otherwise as necessary to fulfill our obligations under this
          agreement.
        </p>
        <p>
          10.10 Right to Receive Documentation of Electronic Funds Transfers
        </p>
        <p>
          10.10.1 Statements. You will have access to an electronic monthly
          statement that can be viewed on the Aeldra App.
        </p>
        <p>
          10.10.2 Direct Deposits or Automated Credits. If you have arranged to
          have direct deposits made to your Checking Account at least once every
          sixty (60) days from the same person or company, call 1-844-333-3101
          to find out if thedeposit has been made.
        </p>
        <p>10. 11 Preauthorized payments</p>
        <p>
          10.11.1. Right to Stop Payment of Pre-authorized Transfers and
          Procedures for Doing So If you scheduled a one-time ACH transfer over
          the phone or recurring ACH transfers using a written
          authorizationform, you can stop any of these payments. Here’s how:To
          stop a recurring ACH transfer to a merchant you preauthorized to debit
          your Checking Account, please contactthe merchant to request
          cancellation of the recurring payment.If the merchant with whom you
          arranged recurring ACH transfers from your Checking Account is unable
          or unwillingto stop the transfer, call Aeldra Concierge Services at
          1-844-333-3101 or email concierge@aeldra.com to request astop on such
          payment. Your request to stop payment must be received three (3)
          business days or more before thepayment is scheduled to be made. Such
          a stop payment request will cancel a single, i.e. one (1) payment. If
          you wantto permanently stop all recurring payments to a specific
          merchant, you may be required to put your request inwriting and email
          it to Aeldra Concierge Services at concierge@Aeldra.com within
          fourteen (14) days after you call. Ifwe ask you to put your request in
          writing, you will need to tell us; the name of the payee, the dollar
          amount of thepayment and the date of the payment. If written stop
          payment notification is required but is not received withinfourteen
          (14) days, only the first payment will be stopped, and all recurring
          payments will be honored as originallyauthorized.
        </p>
        <p>
          10.11.2. Notice of Varying Amounts If the recurring ACH transfers you
          make might vary in amount, the person you are going to pay will tell
          you the transfer date and the amount of the transfer ten (10) days
          before each payment is scheduled to take place. (You may choose
          instead to get this notice only when the payment would differ by more
          than a certain amount from the previous payment, or when the amount
          would fall outside certain limits you set).
        </p>
        <p>
          10.11.3 Liability for Failure to Stop Payment of Preauthorized
          Transfer If you order us to stop a preauthorized payment three (3)
          business days or more before the transfer is scheduled,and we do not
          do so, we will be liable for your losses or damages.
        </p>
        <p>10.12. Our Liability for Failure to Complete Transactions</p>
        <p>
          If we do not properly complete a transaction from your Checking
          Account or Card on time or in the correct amount according to our
          Agreement with you; we will be liable for your losses or damages.
          However, there are someexceptions. We will not be liable, for
          instance:
        </p>
        <p>
          10.12.1 If through no fault of ours, you do not have enough funds
          available in your Checking Account to completethe transaction;
        </p>
        <p>
          10.12.2 If a merchant refuses to accept your Card or Checking Account
          number;
        </p>
        <p>
          10.12.3 If an ATM where you are making a cash withdrawal does not have
          enough cash;
        </p>
        <p>
          10.12.4 If an electronic terminal where you are making a transaction
          does not operate properly, and you knew about the problem when you
          initiated the transaction;
        </p>
        <p>
          10.12.5 If access to your Card or Checking Account has been blocked
          after you reported your Card or Checking Account number lost or
          stolen;
        </p>
        <p>
          10.12.6 If there is a hold or your funds are subject to legal or
          administrative process or other encumbrance restricting their use;
        </p>
        <p>
          10.12.7 If we have reason to believe the requested transaction is
          unauthorized;
        </p>
        <p>
          10.12.8 If circumstances beyond our control (such as fire, flood,
          power failure, strike, labor dispute, critical service provider
          failure, computer breakdown, telephone line disruption, government or
          regulatory action, or a natural disaster) prevent the completion of
          the transaction, despite reasonable precautions that we have taken; or
        </p>
        <p>10.12.9 Any other exception stated in our Agreement with you.</p>
        <p>
          10.13 ATM feesWhen you use an ATM, you may be charged a fee by the ATM
          operator or any network used and you may be charged a fee for a
          balance inquiry even if you do not complete a fund transfer.
        </p>
        <p>10.14. Information About Your Right to Dispute Errors</p>
        <p>
          ‍In case of errors or questions about your electronic transactions,
          call 1-844-333-3101 or email us at concierge@aeldra.com as soon as you
          can, if you think your statement or receipt is wrong or if you need
          more information about a transaction listed in the statement or
          receipt. We must hear from you no later than sixty (60) days after we
          sent the FIRST statement on which the problem or error appeared.
        </p>
        <p>
          • Tell us your name, Checking Account number and/or 16-digit Card
          number.
        </p>
        <p>
          • Describe the error or the transfer you are unsure about, and explain
          as clearly as you can why you believe it is an error or why you need
          more information.
        </p>
        <p>• Tell us the dollar amount of the suspected error.</p>
        <p>
          If you provide this information orally, we may require that you send
          your complaint or question in writing within ten (10) business days.
        </p>
        <p>
          We will determine whether an error occurred within ten (10) business
          days after we hear from you and will correct any error promptly. If we
          need more time, however, we may take up to forty-five (45) days to
          investigate your complaint or question. If we decide to do this, we
          will credit your Checking Account within ten (10) business days for
          the amount you think is in error, so that you will have use of the
          money during the time it takes to complete the investigation. If we
          ask you to put your complaint or question in writing and you do not
          provide it within ten (10) business days, we may not credit your
          Checking Account.
        </p>
        <p>
          For errors involving new Checking Accounts; transactions with a
          merchant’s POS terminal, whether in-person, online, by telephone,
          mail, or otherwise; or foreign-initiated transactions, we may take up
          to ninety (90) days to investigate your complaint or question. For new
          Checking Accounts, we may take up to twenty (20) business days to
          credit your Checking Account for the amount you think is in error. For
          errors involving unauthorized preauthorized debits (“ACH”), we will
          ask you to complete and sign an “Affidavit of Unauthorized ACH” and
          return it to us to assist in our investigation.
        </p>
        <p>
          We will tell you the results within three (3) business days after
          completing the investigation. If we decide that there was no error, we
          will send you a written explanation. Copies of the documentation used
          in the investigation may be obtained by contacting us at
          concierge@aeldra.com or by calling 1-844-333-3101.
        </p>
        <p>10.15. English Language Controls</p>
        <p>
          ‍Any translation of this Agreement is provided for your convenience.
          The meaning of the terms, conditions and representations herein are
          subject to definitions and interpretations in the English language.
          Any translation provided may not accurately represent the information
          in the original English.
        </p>
        <p>10.16. Aeldra Concierge Services</p>
        <p>
          ‍For member service assistance or additional information regarding
          your Checking Account, please contact concierge@aeldra.com or call
          1-844-333-3101. Concierge Service agents are available to answer your
          calls 24/7.
        </p>
        <p>10.17. Telephone Monitoring/Recording</p>
        <p>
          ‍From time to time we may monitor and/or record telephone calls
          between you & us to assure the quality of our member service team or
          as required by applicable law.
        </p>
        <p>10.18. No Warranty Regarding Goods or Services as Applicable</p>
        <p>
          ‍We are not responsible for the quality, safety, legality, or any
          other aspect of any goods or services you purchase using your Checking
          Account.
        </p>
        <p>10.19. Arbitration</p>
        <p>
          ‍Any claim, dispute, or controversy (“Claim”) arising out of or
          relating in any way to: i) this Agreement; ii) the Checking Account;
          iii) your acquisition of the Checking Account; iv) your use of the
          Checking Account; v) the amount of available funds in the Checking
          Account; vi) advertisements, promotions or oral or written statements
          related to the Checking Account, as well as goods or services
          purchased with the Checking Account; vii) the benefits and services
          related to the Checking Account; or viii) transactions made using the
          Checking Account, no matter how described, pleaded or styled, shall be
          FINALLY and EXCLUSIVELY resolved by binding individual arbitration
          conducted by the American Arbitration Association (“AAA”) under its
          Consumer Arbitration Rules. This arbitration agreement is made
          pursuant to a transaction involving interstate commerce, and shall be
          governed by the Federal Arbitration Act (9 U.S.C. 1-16).
        </p>
        <p>
          We will pay the initial filing fee to commence arbitration and any
          arbitration hearing that you attend shall take place in the federal
          judicial district of your residence.
        </p>
        <p>
          ARBITRATION OF YOUR CLAIM IS MANDATORY AND BINDING. NEITHER PARTY WILL
          HAVE THE RIGHT TO LITIGATE THAT CLAIM THROUGH A COURT. IN ARBITRATION,
          NEITHER PARTY WILL HAVE THE RIGHT TO A JURY TRIAL OR TO ENGAGE IN
          DISCOVERY, EXCEPT AS PROVIDED FOR IN THE AAA CODE OF PROCEDURE.
        </p>
        <p>
          For a copy of the procedures, to file a Claim or for other information
          about this organization, contact it at: AAA, 335 Madison Avenue, New
          York, NY 10017, or at www.adr.org.
        </p>
        <p>
          All determinations as to the scope, interpretation, enforceability and
          validity of this Agreement shall be made final exclusively by the
          arbitrator, which award shall be binding and final. Judgment on the
          arbitration award may be entered in any court having jurisdiction.
        </p>
        <p>
          NO CLASS ACTION, OR OTHER REPRESENTATIVE ACTION OR PRIVATE ATTORNEY
          GENERAL ACTION OR JOINDER OR CONSOLIDATION OF ANY CLAIM WITH A CLAIM
          OF ANOTHER PERSON OR CLASS OF CLAIMANTS SHALL BE ALLOWABLE.
        </p>
        <p>
          This arbitration provision shall survive: i) the termination of the
          Agreement; ii) the bankruptcy of any party; iii) any transfer, sale or
          assignment of your Checking Account, or any amounts owed on your
          Checking Accountx, to any other person or entity; or iv) closing of
          the Checking Account. If any portion of this arbitration provision is
          deemed invalid or unenforceable, the remaining portions shall remain
          in force.
        </p>
        <p>
          IF YOU DO NOT AGREE TO THE TERMS OF THIS ARBITRATION AGREEMENT, DO NOT
          ACTIVATE OR USE THE CARD OR CHECKING ACCOUNT. CALL 1-844-333-3101 TO
          CLOSE THE CHECKING ACCOUNT AND REQUEST A REFUND, IF APPLICABLE.
        </p>
      </>
    ),
  },
  {
    question: '11. Truth in Savings Disclosure',
    answer: (
      <>
        <p>11.1 Opening Deposit and Balance Requirements</p>
        <p>
          There is no initial deposit required to open an Account. For minimum
          deposit requirements to maintain the account , please refer to section
          6.6
        </p>
        ‍<p>‍11.2 Rate Information</p>
        <p>
          For rate information on the Checking Account, please refer to section
          6.6. The current interest rate and Annual Percentage Yield (“APY”) for
          the Checking Account appear in section 6.6. This is a variable rate
          account. We may, at our discretion, change the interest rate and APY
          for the Checking Account at any time. Interest begins to accrue no
          later than the business day the deposit is applied to the Checking
          Account. Interest will be compounded daily and credited to the
          Checking Account on a monthly basis.
        </p>
        ‍<p>11.3 Balance Information</p>
        <p>
          Checking Account interest is calculated using the daily balance
          calculation method. This method applies a daily periodic rate to the
          principal balance in the Checking Account each day.
        </p>
        ‍<p>11.4 Fees and Charges.</p>
        <p>
          Subject to applicable law, you agree to pay us the fees and charges
          shown in the Rate and Fee Schedule in section 6.6 as are applicable to
          your Account or for other services performed by us. You agree that the
          fees and charges may be changed by us from time to time and authorize
          us to charge your Account for their payment whether or noteach charge
          results in an overdraft of your Account. Existing and future charges
          may be based upon the overallcosts of providing Account services and
          may or may not be based upon the direct cost or expense associated
          with providing the particular service involved. The charges may be
          based on consideration of profit, competitive position,deterrence of
          misuse of Account privileges by customers, and the safety and
          soundness of the financial institution.We will notify you of the
          changes, to the extent required by law.
        </p>
        ‍<p>11.5. General Deposits and Withdrawals Rules.</p>
        <p>
          Use of the Account is subject to all applicable rules and customs of
          any clearing house or other association involved in transactions. Bank
          does not waive its rights by delaying or failing to exercise them at
          any time. If any provision ofthis Agreement shall be determined to be
          invalid or unenforceable under any rule, law, or regulation of any
          governmental agency, local, state, or federal, the validity or
          enforceability of any other provision of this Agreement shall not be
          affected.
        </p>
        ‍<p>11.6. Deposit Rules.</p>
        <p>The following terms apply to deposits made to your Account:</p>‍
        <p>
          11.6.1 Cash or Check Deposits. You cannot deposit checks or cash into
          your Account.
        </p>
        <p>
          11.6.2 Direct Deposits. If we offer direct deposit services, you must
          notify us at least 30 days prior to the next scheduled direct deposit
          or preauthorized transfer if you wish to cancel the direct deposit. If
          any amount depositedmust be returned for any reason, you authorize us
          to deduct the amount from your Account.
        </p>
        <p>
          11.6.3 Crediting of Deposits. If you make a deposit before 5pm Pacific
          Time on a business day that we are open, wewill consider that day to
          be the day of your deposit. However, if you make a deposit after 5pm
          Pacific Time on a non-business day, we will consider that the deposit
          was made on the next business day.
        </p>
        <p>
          11.6.4 Deposit Discrepancies. When you make a deposit to your Account,
          we will credit your account for the amount of the deposited items. We
          reserve the right to review the deposit and confirm the amount of
          Account youdeposited but are not required to do so. If after any
          review we determine that the amount credited to your Accountis
          incorrect, we may adjust your Account for the amount of the
          discrepancy but reserve the right not to do so if thediscrepancy would
          not be a disadvantage to you.
        </p>
        <p>
          11.6.5 Deposit Liability. Bank is not liable for any deposits not
          received by Bank.
        </p>
        <p>
          11.7 Withdrawal Rules.The following terms apply to withdrawals from
          your Account:
        </p>
        <p>
          (A) Manner of Withdrawal. You may make withdrawals from your Account
          in any manner that is permitted by us. Withdrawals and transfers from
          your Account may be restricted as provided in the Agreement or by
          applicable law.
        </p>
        <p>
          (B) Withdrawal Restrictions / No Overdrafts. You are not permitted to
          overdraw your Account. If there are available funds to cover some, but
          not all, of the withdrawals or other debits to your Account on a
          single business day, we willpost the withdrawals or debits in any
          order we may choose at Bank’s sole discretion. If there are
          insufficient funds available in your Account to cover a withdrawal or
          debit presented against your Account, we may reject thewithdrawals.
          Even if we choose to pay one or more overdrafts as a courtesy to you,
          in our sole discretion, we arenot obligated to cover any future
          overdrafts. If your Account balance becomes negative for any reason,
          you mustmake a deposit immediately to cover the negative balance. If
          your Account has a negative balance for sixty (60) calendar days or
          more it will be closed. In the event you fail to pay the amount of any
          overdraft and Bank refersyour overdrawn Account to an attorney for
          collection, you agree to pay all reasonable expenses, including, but
          notlimited to, reasonable attorneys’ fees and court costs incurred by
          Bank as a result of your account being overdrawn.
        </p>
        <p>11.8 Forfeiture of Interest</p>
        <p>
          If you or we close your Account before interest is credited, you will
          not receive the accrued interest.
        </p>
      </>
    ),
  },
  {
    question: '12. Fee Schedule',
    answer: (
      <p>
        Aeldra does not charge transaction fees or a monthly account fee. All
        other fee amounts will be withdrawn from your Checking Account and will
        be assessed as long as there is a remaining balance in your Checking
        Account,except where prohibited by law. Any time your remaining balance
        is less than the fee amount being assessed, the balance of your Checking
        Account will be applied to the fee amount resulting in a zero balance on
        your Checking Account. For all Fee information please refer to section
        6.6
      </p>
    ),
  },
  {
    question: '13. General Funds Availability',
    answer: (
      <>
        <p>
          Information contained in this section is provided to assist you in
          understanding our Funds Availability Policy. All deposits into
          accounts opened through the Bank are processed at a Bank processing
          facility.
        </p>
        ‍<p className="bold">13.1. General policy</p>
        <p>
          ‍We make funds available according to the type of deposit and when the
          funds are applied or credited to your Account and in accordance with
          applicable law. Some types of deposits may not be available for
          immediate use.When we delay the availability of funds or place a hold
          on a deposit made to your Account, you may not withdraw those funds,
          and we will not use them to pay any debits. We have the right to
          refuse any deposit. The length of thedelay in the availability of
          funds varies depending on the type of deposit. If final payment is not
          received on any item you have deposited into your Account, or if any
          direct deposit ACH transfer credit is returned to Bank for any reason,
          you agree to pay the amount of the returned item. You may only deposit
          funds that are immediately available. We do not accept deposits made
          in cash, by check, or any form of deposit denominated in a foreign
          currency. Any attempt to mail us any deposit consisting of cash,
          check(s) or foreign currency will be sent back to the address we have
          on file for you. We are not liable if you do not receive the returned
          funds. You may not make deposits into your Account at any automated
          teller machine (“ATM”).
        </p>
        <p className="bold">13.2.Availability</p>
        <p>
          ‍The length of the delay in the availability of funds varies depending
          on the type of deposit.
        </p>
        ‍<p className="bold">13.3. Business Days</p>
        <p>
          ‍The length of the delay in the availability of funds is counted in
          business days from the day your deposit is applied to your Checking
          Account. For purposes of these disclosures, our business days are
          Monday through Friday. Federal holidays are not included. Deposits
          received by midnight Pacific Time will be considered current-day
          deposits. Any deposits received after that time will be processed the
          following business day.
        </p>
        ‍<p className="bold">13.4. Same Day Availability</p>
        <p>
          ‍Funds received from pre-authorized electronic payments such as
          payroll direct deposits, or other pre-authorized electronic payments
          will be available on the day the deposit is applied to your Checking
          Account. ACH Credits received from an external bank account will be
          applied to the Checking Account when we have verified the external
          account and received payment on collected funds. Once the funds are
          applied to the Checking Account, they will have same day availability.
        </p>
        ‍<p className="bold">13.5. Availability of ACH Transfers.</p>
        <p>
          Our policy is to make funds from electronic direct deposits available
          on the day we receive the deposit. However, it may take several days
          for us to receive ACH transfers from a linked bank account. Once they
          are available, you can withdraw the funds and make other transfers
          subject to the disclosed limitations. As a reminder, ACH debit
          transactions where funds are pulled from a linked account are not
          considered electronic direct deposits and wemay hold those funds to
          ensure the transaction is not returned or rejected by the paying bank
          for any reason.Electronic transfers initiated through the Mobile App
          may take up to nine (9) business days from the date of theinitial
          request but will post on the payment date of the deposit once the
          money has reached the Bank. The Bank reserves the right to restrict
          access to, or reject, any ACH transfer that the Bank believes is
          involved in illegal activity.{' '}
        </p>
        ‍<p className="bold">13.6. Transaction Posting Order.</p>
        <p>
          Bank reserves the right to decide the order of the items Bank will pay
          and which items will be returned (if any).Bank’s posting order may not
          be the same as the order in which you conducted a transaction, and
          this may impact available funds at the time the item is paid.
          Generally, Bank posts the following three transaction types (to
          theextent applicable to your account) after the close of each business
          day in the following order:  Deposits and credits Bank receives
          before the daily cut-off time will be posted before any withdrawals.
          (Referto Bank’s cut-off Time and/or Funds Availability section for
          cut-off time description. Your debits and withdrawals will be posted
          in date/time order, based on the date and time associated
          witheachtransaction
        </p>
      </>
    ),
  },
  {
    question: '14. Terms of Use & Privacy Policy',
    answer: (
      <>
        <p>14.1. Terms of Use</p>
        <p>
          By accessing and/or using the Services, you acknowledge that you have
          read, understood, and agree to be bound by the Terms of Use set forth
          at{' '}
          <a href="https://www.aeldra.com/terms-of-use">
            https://www.aeldra.com/terms-of-use
          </a>
          . If you do not agree with these terms and conditions, you may not
          access or use the Services. By using or accessing the Services or by
          clicking to agree to these Terms when that option is made available to
          you, you represent that you have read and understand these Terms.
        </p>
        ‍<p>14.2. Privacy Policy</p>
        <p>
          Your use of and access to the Services are subject at all times to our
          privacy policy set forth at{' '}
          <a href="https://www.aeldra.com/privacy-policy">
            https://www.aeldra.com/privacy-policy
          </a>
          . By using or accessing the Services or by clicking to agree to the
          privacy policy when that option is made available to you, you
          represent that you have read and understand our Privacy Policy and you
          agree to be bound by our Privacy Policy.{' '}
        </p>
      </>
    ),
  },
  {
    question: '15. Electronic Communication Agreement',
    answer: (
      <>
        <p>
          Please read this information carefully and print or download a copy
          for your files.
        </p>
        <p>
          This Electronic Communications Agreement (“Disclosure”) applies to all
          communications related to the demand deposit account (“Checking
          Account”) and accompanying services available through Aeldra.com (the
          “Website”) and mobile application (“Aeldra App”). This Disclosure
          supplements and is to be construed in accordance with the terms
          contained in the deposit account agreement (“Deposit Account
          Agreement”) you received when you obtained the demand deposit account.
        </p>
        <p>
          “We”, “us”, “our”, refers to Aeldra Financial Inc. “Issuing Bank”
          means Blue Ridge Bank. “You” and “your” mean the person or persons
          identified on the Aeldra Account. “Communication” means any customer
          agreements or amendments thereto, disclosures, notices, responses to
          claims, transaction histories, privacy policies and all other
          information in connection with the Aeldra Account or related products
          and service, including but not limited to information that we are
          required by law to provide you in writing.
        </p>
        <p>
          The Aeldra Account is intended for use only by person(s) who are
          willing and able to receive notices and communications exclusively
          through the Website, the Aeldra App or via electronic mail
          (“E-mail”).If you do not agree to the legally-required notices and
          communications described herein in electronic and not paper form, you
          may not open an Aeldra Account. Similarly, if after providing your
          consent hereunder, you withdraw such consent, your Aeldra Account will
          be closed.
        </p>

        <p>15.1. Scope of Communications to Be Provided in Electronic Form.</p>
        <p>
          When you use a product or service to which this Disclosure applies,
          you agree that we may provide you with any Communications in
          electronic format, to the extent allowed by law, and that we may
          discontinue sending paper Communications to you. Your consent to
          receive electronic communications and transactions includes, but is
          not limited to:
        </p>
        <ul>
          <li>
            All legal and regulatory disclosures and communications associated
            with your Aeldra Account, including e-statements or other periodic
            statement
          </li>
          <li>Change-in-terms related to your Aeldra Account;</li>
          <li>Privacy policies and notices;</li>
          <li>
            Responses to claims filed in connection with your Aeldra Account;
          </li>
          <li>
            Notices regarding insufficient funds or negative balances; and
          </li>
          <li>
            All other communications between us and you concerning your Aeldra
            Account and any related transactions, products or services.
          </li>
        </ul>
        <p>
          15.2. Method of Providing Communications to You in Electronic From.
        </p>
        <p>
          Communications that we provide to you in electronic form will be
          provided either (1) via email or (2) by access to a web site that is
          designated in an email notice sent to you at the time the information
          is available. Periodic statements will be made available on the 6th
          day of each month and accessible through the Aeldra App. You will not
          receive a notification when your periodic statement is available to be
          viewed. You are responsible to retrieve the periodic statement(s) from
          the App.
        </p>

        <p>15.3. How to Withdraw Consent.</p>
        <p>
          You may exercise your right to withdraw your consent to receive
          Communications in electronic form by contacting us
          concierge@aeldra.com. If you withdraw your consent, we will close your
          Aeldra Account and mail you a refund check for any amount remaining
          balance(s) in your Aeldra Account, in accordance with the terms of
          your Deposit Account Agreement. If you withdraw your consent, the
          legal validity and enforceability of prior Communications delivered in
          electronic form will not be affected.
        </p>

        <p>15.4. How to Update Your Records.</p>
        <p>
          It is your responsibility to provide us with a true, accurate and
          complete email address, contact, and other information related to this
          Disclosure and your Aeldra Account, and to maintain and update
          promptly any changes in this information. You can update information
          (such as your email address) through the Aeldra App, by emailing
          concierge@aeldra.com, or by contacting us at 1-844-333-3101. We are
          not responsible for any delay or failure in your receipt of the
          Communications if we send the Communications to the last email address
          you have provided to us.
        </p>

        <p>15.5. Hardware and Software Requirements.</p>
        <p>
          In order to access, view, and retain electronic Communications that we
          make available to you, you must have:
        </p>
        <ul>
          <li>
            Either a personal computer capable of running one of these
            compatible browsers OR a mobile device running on the below
            versions:
            <ul type="circle">
              <li>Internet Explorer version 9.0 or higher.</li>
              <li>Firefox version 35 or higher.</li>
              <li>Safari version 6.1 or higher.</li>
              <li>Chrome version 38 or higher.</li>
              <li>
                an Apple iPhone or iPad running iOS version 8.0 or higher.
              </li>
              <li>an Android phone running Android version 4.1 or higher.</li>
            </ul>
          </li>
          <li>
            {' '}
            AND access to an active email account with an Email service
            provider.
          </li>
        </ul>

        <p>
          We may update these requirements as necessary to preserve the ability
          to receive electronic Communications. If there is a substantial change
          in these requirements, you will be notified of the changes
          accordingly.
        </p>

        <p>15.6. Requesting Paper Copies.</p>
        <p>
          We will not automatically send you a paper copy of any Communication,
          however, we reserve the right, but assume no obligation, to provide a
          paper (instead of electronic) copy of any Communication that you have
          authorized us to provide electronically. You can obtain a paper copy
          of an electronic Communication by printing the Communication.
        </p>

        <p>15.7. Communications in Writing.</p>
        <p>
          All Communications in either electronic or paper format from us to you
          will be considered “in writing”. You should print or download for your
          records a copy of this Disclosure and any other Communication that is
          important to you.
        </p>

        <p>15.8. Federal Law.</p>
        <p>
          You acknowledge and agree that your consent to electronic
          Communications is being provided in connection with a transaction
          affecting interstate commerce that is subject to federal Electronic
          Signatures in Global and National Commerce Act (the “Act”), and that
          you and we both intend that the Act apply to the fullest extent
          possible to validate our ability to conduct business with you by
          electronic means.
        </p>

        <p>15.9. Termination/Changes.</p>
        <p>
          We reserve the right, at our sole discretion, to discontinue the
          provision of your electronic Communications, or to terminate or change
          the terms and conditions on which we provide electronic
          Communications. We will provide you with notice of any such
          termination or change as required by law.
        </p>

        <p>15.10. Consent.</p>
        <p>
          By checking “I agree”, you adopt the check as your electronic
          signature and you give your affirmative consent to provide electronic
          Communications to you as described herein. You further agree that your
          computer satisfies the hardware and software requirements specified
          above and that you have provided us with a current e-mail address at
          which we may send electronic Communications to you.
        </p>
      </>
    ),
  },
].map(({ question, answer }, id) => ({
  question,
  answer,
  id,
}));

const productAgreement = () => (
  <Layout>
    <SEO
      title="Product Agreement | Aeldra"
      description="You are wholly responsible for the use of your Checking Account according to the terms and conditions of this Agreement."
    />

    <div className="no-hero-layout">
      <div className="container">
        <div className="row">
          <div className="col-md col-8 offset-2">
            <h1>Product agreement</h1>

            <p>As of July 1, 2022</p>

            <p>Deposit Agreement and Disclosures</p>

            <div className="terms-content">
              <Accordion data={productAgreementFaq} />
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
);

export default productAgreement;
